import React from 'react';

export function ZoomableImage({ src, width }: { src: string, width?: number }) {
    return (
        <div 
            className="zoomable-image"
            style={{
                width: width,
            }}
            >
            <input type="checkbox" id={src} />
            <label htmlFor={src}>
                <img 
                    src={src} 
                    alt="Screenshot" 
                    className="screenshot"
                    />
                <div className="text-center text-sm">Click to zoom</div>
            </label>
            <div className="full-image-container">  
                <label 
                    htmlFor={src}
                    className="full-image"
                    >
                    <img src={src} alt="Screenshot" />
                </label>
            </div>
        </div>
    );
}
