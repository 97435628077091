import React from 'react';
import ReactDOM from 'react-dom';
import "./index.less";
import { Home } from './pages/home';

function Index() {
    return (
        <Home />
    );
}

ReactDOM.render(
    <Index />,
    document.getElementById('root'),
);