import React from 'react';
import { Link } from "react-router-dom";

export interface ILayoutProps {
    
    //
    // Show the message at the top.
    //
    showMessage?: boolean,

    //
    // Children of the layout.
    //
    children?: any,
}

export default function Layout({ showMessage, children }: ILayoutProps) {
    return (
        <div>
            {showMessage 
                && <div className="message flex flex-col items-center justify-center bg-black text-sm text-center p-4">
                    {/* <div>
                        Data-Forge Notebook v2 is being open-sourced in 2022. <a target="_blank" href="https://github.com/data-forge-notebook/wiki/wiki/Future-Plans">Read more about the decision.</a>
                    </div>
                    <div>
                        <a target="_blank" href="https://twitter.com/codecapers">Follow the developer on Twitter for updates</a>
                    </div> */}

                    <div>
                        DFN v1 Pro is now free, signup for your download link.
                    </div>
                    <div className="mt-1">
                        <a target="_blank" href="https://github.com/data-forge-notebook/data-forge-notebook/">DFN v2 is open-source</a> and nightly builds have started. 
                    </div>
                </div>
            }

            <header style={{ maxWidth: "100%" }}>
                <nav className="flex flex-col">
                    <div className="flex flex-wrap items-center">
                        <a 
                            className="hidden sm:flex"
                            href="/"
                            >
                            <img 
                                className="logo"
                                src="images/logo.png" 
                                alt="Data-Forge Notebook Logo" 
                                />
                        </a>
                        <div className="flex-grow" />
                        <a href="/#about">About</a>
                        <a href="https://github.com/data-forge-notebook/data-forge-notebook/releases" target="_blank">Download</a>
                        <a href="/#signup">Subscribe</a>
                        <a className="hidden sm:flex" href="https://codecapers.com.au/about#support-my-work" target="_blank">Give support</a>
                        <div className="flex-grow flex sm:hidden" />
                    </div>
                </nav>                
            </header>

            <main>
                {children}
            </main>

            <footer className="text-center pt-16 pb-16">
                © 2024 Code Capers 
            </footer>
        </div>
    );
}