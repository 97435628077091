import React from 'react';
import Layout from '../components/layout';
import { ZoomableImage } from '../components/zoomable-image';

export interface IHomeProps {
}

interface IHomeState {
}

export class Home extends React.Component<IHomeProps, IHomeState> {

    constructor(props: IHomeProps) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <Layout showMessage={false}>
                <div className="relative">
                    <section className="blue pb-10">
                        <div className="narrow-container flex flex-col items-center">
                            <img className="logo" src="images/logo.png" alt="Data-Forge Notebook logo" />
                            <div className="text-2xl">
                                Exploratory coding and data analysis with JavaScript and TypeScript
                            </div>
                            <div className="text-lg">
                                For Windows, MacOS and Linux
                            </div>
                            
                            <a 
                                target="_blank"
                                href="https://github.com/data-forge-notebook/data-forge-notebook/releases"
                                className="mt-8 no-underline text-gray-900 hover:text-gray-900 bg-gray-200 font-medium rounded-lg text-xl px-6 py-4 text-center inline-flex items-center me-2 mb-2"
                                >
                                Download the latest release
                            </a>
                            
                            <div className="vid-container mt-8">
                                <iframe 
                                    width="560" 
                                    height="315" 
                                    src="https://www.youtube.com/embed/-kmjIBJMWsU?si=nGF88CGSgKF2N_Q7" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen
                                    >
                                </iframe>
                            </div>

                            {/* <a 
                                className="mt-8"
                                target="_blank" 
                                href="https://www.youtube.com/channel/UCOxw0jy384_wFRwspgq7qMQ"
                                >
                                Watch more videos
                            </a> */}
                        </div>
                    </section>

                    <section className="white mt-8 pt-16 pb-16 text-left">
                        <div className="wide-container flex flex-col items-center md:flex-row">
                            <div className="flex-grow pr-4">
                                <p className="text-2xl text-black">Want to do visual prototyping and exploratory data analysis?</p>
                                <p className="mt-2 text-xl text-gray-500">But you need to work in JavaScript</p>
                            </div>

                            <div className="mt-4 md:mt-0">
                                <ZoomableImage
                                    src="images/screenshots/Screenshot_1.png"
                                    width={300}
                                    />
                            </div>                
                        </div>

                        <div className="wide-container flex flex-col items-center md:flex-row mt-10">

                            <div className="hidden md:flex">
                                <ZoomableImage
                                    src="images/screenshots/Screenshot_2.png"
                                    width={300}
                                    />
                            </div>

                            <div className="flex-grow pl-4">
                                <p className="text-2xl text-black">Love the idea of Notebook-style or literate programing?</p>
                                <p className="mt-2 text-xl text-gray-500">But your production environment is JavaScript</p>
                            </div>

                            <div className="mt-4 md:mt-0 md:hidden">
                                <ZoomableImage
                                    src="images/screenshots/Screenshot_2.png"
                                    width={300}
                                    />
                            </div>
                        </div>

                        <div className="wide-container flex flex-col items-center md:flex-row     mt-10">
                            <div className="flex-grow pr-4">
                                <p className="text-2xl text-black">Learning JavaScript?</p>
                                <p className="mt-2 text-xl text-gray-500">Data-Forge Notebook offers a friendly and forgiving environment that includes Node.js, automatically installs npm modules, has zero configuration and just works.</p>
                                <p className="mt-2 text-xl text-gray-500">Just fire it up and start coding</p>
                            </div>

                            <div className="mt-4 md:mt-0">
                                <ZoomableImage
                                    src="images/screenshots/Screenshot_3.png"
                                    width={300}
                                    />
                            </div>
                        </div>
                    </section>

                    <section className="blue pt-16 pb-16 text-left">
                        <div className="narrow-container">
                            <div className="text-3xl">Example notebooks</div>
                            <p className="mt-8">
                                Data-Forge Notebook includes many example notebooks to help you get started and working 
                                quickly with JavaScript.
                            </p>

                            <p className="mt-4">
                                Click these links to see example exported notebooks:
                            </p>
                            
                            <ul className="mt-4 list-disc list-inside">
                                <li>
                                    Examples of Data-Forge Notebook visualizations exported to
                                    <ul className="list-disc list-inside pl-8">
                                        <li>
                                            <a href="https://data-forge-notebook.github.io/visualization-examples/" target="_blank">a web page</a>
                                        </li>
                                        <li>
                                            <a href="https://wiki.data-forge-notebook.com/visualizing-data" target="_blank">to markdown</a>
                                        </li>                        
                                    </ul>
                                </li>
                                <li className="mt-1">
                                    <a href="http://jscheatsheet.the-data-wrangler.com" target="_blank">JavaScript cheat sheet (HTML)</a>
                                </li>
                                <li className="mt-1">
                                    <a href="http://dfcheatsheet.the-data-wrangler.com" target="_blank">Data-Forge cheat sheet (HTML)</a>
                                </li>
                                <li className="mt-1">
                                    <a href="https://gist.github.com/ashleydavis/244b8f7ef91a36b7f8e1b2f0dd90c6f5" target="_blank">Backtesting a trading strategy (markdown format)</a>
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section className="white pt-16 pb-16">
                        <div className="narrow-container">
                            <div className="text-3xl">What people are saying</div>

                            {/*
                            <blockquote className="pt-16">
                                <p>I can experiment and visualize my data easily</p>
                                <p className="author">Khursani, Fullstack Software Engineer at Vase</p>
                            </blockquote>
                            */}

                            <blockquote className="pt-16">
                                <p>An invaluable tool for prototyping with JavaScript. As a web developer, using JavaScript all the time, this is a game changer.</p>
                                <p className="author">Paolo Leopardi</p>
                            </blockquote>

                            <blockquote className="pt-16">
                                <p>Amazing product that enables analysis and visualization while using JavaScript</p>
                                <p className="author">Brian Enochson, CTO at The Java Experts</p>
                            </blockquote>

                            <blockquote className="pt-16">
                                <p>Quick and easy way to get up and running in JavaScript, love the ability to visualize results as you code</p>
                                <p className="author">Peter Srajer, Senior Data Scientist - Geospatial at Wellsite Masters</p>
                            </blockquote>

                            <blockquote className="pt-16">
                                <p>The key is that it "just works" right out of the install.</p>
                                <p className="author">Matthew Abts, CPA</p>
                            </blockquote>

                            {/*
                            <blockquote className="pt-16">
                                <p>Data-Forge Notebook is making JavaScript development for financial technology software solutions seamless and straightforward.  It's shortened my development timeline and given me more confidence in my custom calculations through the interactive interface</p>
                                <p className="author">Dylan Hall, CTO at Persee Ventures</p>
                            </blockquote>
                            */}

                            <blockquote className="pt-16">
                                <p>No more switching between TypeScript, R and Python when doing data exploration, now I can keep my data extraction, shaping and visualisation all in TypeScript!</p>
                                <p className="author">Eugene Duvenage, Technology and Innovation Specialist</p>
                            </blockquote>
                        </div>
                    </section>

                    <section className="blue pt-16 pb-16">
                        <div className="narrow-container flex flex-col items-center text-xl">
                            <div className="logo">
                                <img src="images/logo.png" alt="Data-Forge Notebook logo" />
                            </div>
                        
                            <p className="mt-3">Includes everything you need. Like a REPL on steroids.</p>
                            <p className="mt-2">Visualize your data as you code</p>
                            <p className="text-lg mt-2 mb-4">Export to runnable Node.js code</p>

                            <ZoomableImage
                                src="images/screenshots/Main.png" 
                                />

                        </div>
                    </section>    

                    <section className="about white pt-16 pb-16" id="about">
                        <div className="narrow-container text-left">
                            <div className="text-3xl">About Data-Forge Notebook</div>
                            <div className="mt-4">
                                <p>Data-Forge Notebook is a desktop application for Windows, Mac and Linux.</p>
                                <p>Prototype JavaScript and TypeScript code in a highly visual fashion.</p>
                                <p>It makes data transformation, analysis and visualization a breeze.</p>
                                <p>
                                    <a href="https://github.com/data-forge-notebook/data-forge-notebook" target="_blank">
                                        DFN v2 is free and open source.
                                    </a>
                                </p>
                                <p>Don't be tied to someone's hosted solution, get the desktop application: maintain privacy for your code and data, work off-line whenever you want.</p>
                            </div>
                            
                            <div>
                                <div className="text-xl text-bold mt-4">Version 1 features</div>
                                <ul>
                                    <li>
                                        Create JavaScript and TypeScript notebooks
                                    </li>
                                    <li>
                                        Code using the latest JavaScript features
                                    </li>
                                    <li>
                                        Full language support, anything you can do in Node.js you can also do in Data-Forge Notebook (but with live visualizations!)
                                    </li>
                                    <li>
                                        Zero configuration, just install and start coding
                                    </li>
                                    <li>
                                        Visualize JavaScript & JSON data, tabular data, charts, geographic data and more
                                    </li>
                                    <li>
                                        Embedded Node.js is included, no separate download, setup or installation is required
                                    </li>
                                    <li>
                                        Npm modules that you are use are automatically installed
                                    </li>
                                    <li>
                                        Reuse your own Node.js code modules
                                    </li>
                                    <li>
                                        Supports various data formats and databases
                                    </li>
                                    <li>
                                        Direct access to your local file system for loading and saving data
                                    </li>
                                    <li>
                                        Easily plot charts from your data
                                    </li>
                                    <li>
                                        Visualize maps and geo data
                                    </li>
                                    <li>
                                        Numerous example notebooks to help you get started
                                    </li>                       
                                    <li>
                                        Export runnable Node.js code
                                    </li>
                                    <li>
                                        Export cell outputs to data files
                                    </li>
                                </ul>

                                <p>
                                    <a target="_blank" href="https://wiki.data-forge-notebook.com/road-map">See the road map for future plans!</a>
                                </p>

                                <p><a href="mailto:support@data-forge-notebook.com" target="_blank" >Please email</a> and let me know which future features are important to you!</p>

                                <p>
                                    Want to know how it's different to Jupyter Notebook + IJavaScript? 
                                </p>
                                <a href="https://github.com/data-forge-notebook/wiki/wiki/differences-to-ijavascript" target="_blank">
                                    Read this
                                </a>
                            </div>
                        </div>
                    </section>

                    <section className="about white pt-16 pb-16">
                        <div className="narrow-container">

                            <div className="text-3xl">About the developer</div>

                            <p>
                                Data-Forge Notebook is bought to you by <a target="_blank" href="https://www.codecapers.com.au/about">Ashley Davis</a>,
                                creator of <a target="_blank" href="http://www.data-forge-js.com">Data-Forge</a> and author of <a target="_blank" href="http://bit.ly/2t2cJu2">Data Wrangling with JavaScript</a>,  <a target="_blank" href="https://bootstrapping-microservices.com/">Bootstrapping Microservices</a> and <a target="_blank" href="https://rapidfullstackdevelopment.com/">Rapid Fullstack Development</a>.
                            </p>

                            <div>
                                <div className="profile-image">
                                    <img src="images/New-reduced.jpg" alt="Ashley Davis" />
                                </div>
                                <blockquote className="pt-4">
                                    <p>I always kickstart my JavaScript coding with Data-Forge Notebook. I can see visual results immediately while I'm coding and it makes it easy to understand what's going on in the code.</p>
                                    <p className="author">Ashley Davis, Developer of Data-Forge Notebook</p>
                                </blockquote>
                            </div>

                        </div>
                    </section>        

                    <div 
                        id="signup"
                        className="shadow rounded p-3 bg-white border-2 border-gray-500 border-solid mt-16"
                        style={{
                            maxWidth: "600px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                        >
                        {/* <!-- Begin Mailchimp Signup Form --> */}
                        <div id="mc_embed_signup">
                            <form 
                                action="https://data-forge-notebook.us18.list-manage.com/subscribe/post?u=f0220333a13f2b0e980819dbe&amp;id=c526152b6e" 
                                method="post" 
                                id="mc-embedded-subscribe-form" 
                                name="mc-embedded-subscribe-form" 
                                className="validate" 
                                target="_blank" 
                                noValidate
                                style={{ padding: "5px", }}
                                >
                                <div id="mc_embed_signup_scroll">
                                <label htmlFor="mce-EMAIL" style={{ marginTop: "3px" }}>Subscribe</label>
                                <div className="mb-2">Stay up to date with news and resources on DFN</div>
                                <input 
                                    type="email" 
                                    defaultValue="" 
                                    name="EMAIL" 
                                    className="email" 
                                    id="mce-EMAIL" 
                                    placeholder="enter your email address" 
                                    required 
                                    style={{ width: "90%" }} 
                                    onClick={evt => {
                                        evt.stopPropagation();
                                    }}
                                    />
                                {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                                <div style={{ position: "absolute", left: "-5000px", }}>
                                    <input type="text" name="b_f0220333a13f2b0e980819dbe_c526152b6e" tabIndex={-1} value="" readOnly />
                                </div>
                                <div className="clear">
                                    <input 
                                        type="submit" 
                                        defaultValue="Get DFN Free" 
                                        name="subscribe" 
                                        id="mc-embedded-subscribe" 
                                        className="button" 
                                        style={{ paddingLeft: "1em", paddingRight: "1em", width: "auto", height: "auto" }} 
                                        onClick={evt => {
                                            var email = evt.currentTarget.value;
                                            if (email) {
                                                email = email.trim();
                                            }
                                        }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* <!--End mc_embed_signup--> */}
                    </div>

                    <section className="blue pt-16">
                        <div className="narrow-container flex flex-col items-center">
                            <img className="logo" src="images/logo.png" alt="Data-Forge Notebook logo" />
                            <div className="text-2xl">
                                Exploratory coding and data analysis for JavaScript and TypeScript
                            </div>
                            <p>
                                Want to donate to help fund this project?
                                Use PayPal and send to ashley@codecapers.com.au
                            </p>
                            <p>Email on <a href="mailto:support@data-forge-notebook.com" target="_blank">support@data-forge-notebook.com</a></p>
                            <p>Follow on Twitter at <a href="https://twitter.com/codecapers" target="_blank">@codecapers</a></p>
                        </div>
                    </section>

                </div>
            </Layout>
        );
    }
}
